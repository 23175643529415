// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-position-page-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/templates/positionPage.js" /* webpackChunkName: "component---src-templates-position-page-js" */),
  "component---src-pages-404-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-brochure-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/brochure.js" /* webpackChunkName: "component---src-pages-brochure-js" */),
  "component---src-pages-download-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-de-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-fr-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-info-video-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/info/video.js" /* webpackChunkName: "component---src-pages-info-video-js" */),
  "component---src-pages-login-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-securitypaper-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/securitypaper.js" /* webpackChunkName: "component---src-pages-securitypaper-js" */),
  "component---src-pages-sign-in-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-signin-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-support-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-video-js": () => import("/home/rof/src/bitbucket.org/sharekey/sharekey_website/src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

